import gsap, { Power1 } from "gsap";
import { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { imageRoute, queryClient } from "../../utils/api";
import { checkImageSrc, checkTier, Context } from "../../utils/commonFunctions";
import arrWin from "../../assets/img/arrowSpinnerWinItem.png";
import { ModalMultiWinnerSpin } from "../common/Modals/ModalMultiWinnerSpin";
import borderMarket from "../../assets/img/borderMarketItem.svg";
import { ReactComponent as CircleIcon } from "../../assets/img/modalBGItemSpin.svg";
import { Tier } from "../common/Tier";
import { AdminPartsType, ProfileUserSpins } from "../../utils/commonTypes";
import { Modal } from "../common/Modal";
//import { useHistory } from "react-router-dom";

type RoulletePropsType = {
  winnerId: number[];
  allDetails: AdminPartsType[];
  winArr?: AdminPartsType[];
  spin: boolean;
  freeType: boolean;
  spins: ProfileUserSpins[];
  currentBoxType: string;
  currentBoxId: number;
};

const itemWidth = 220;

export const Roullete = (props: RoulletePropsType) => {
  const [visibleMultiWinSpin, setMultiVisibleWinSpin] =
    useState<boolean>(false);
  const desiredImg = useRef<HTMLImageElement>(null);
  const listsRef = useRef<HTMLDivElement>(null);

  const items = props.allDetails;

  useEffect(() => {
    desiredImg.current &&
      listsRef.current &&
      props.winnerId &&
      props.spin &&
      rouletteSpin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.winnerId]);

  const context = useContext(Context);

  // const history = useHistory();
  // useEffect(() => {
  //   !props.spins.filter(
  //     item =>
  //       item.lootbox.type === props.currentBoxType &&
  //       item.lootbox.id === props.currentBoxId,
  //   ).length && history.push("/loot");
  // });

  const rouletteSpin = () => {
    const timeLine = gsap.timeline();
    props.winnerId.forEach((elem, idx, arr) => {
      timeLine.to(listsRef.current, {
        x: -items.findIndex(el => el.id === elem) * itemWidth - itemWidth / 2,
        ease: Power1.easeOut,
        duration: 3,
        onComplete: () => {},
      });
    });
    timeLine.call(() =>
      context?.setMessage(
        `You Win ${props.winArr && props.winArr.length} ${
          props.winArr && props.winArr.length === 1 ? `detail` : "details"
        }`,
      ),
    );
    timeLine.call(() => setMultiVisibleWinSpin(true));
  };

  return (
    <RoulleteContainer>
      <RoulleteIndicator>
        <img src={arrWin} alt="" />
        <img src={arrWin} alt="" />
      </RoulleteIndicator>
      <RoulleteImagesWrapper>
        <RoulleteImagesList ref={listsRef} id="roulette-images-list">
          {items.map(item => (
            <RoulleteItem key={item.id}>
              <BgRobotModal src={borderMarket} alt="" />
              <CircleIconStyled rarity={checkTier(item.partparams)} />
              <RoulleteImg
                ref={desiredImg}
                src={`${imageRoute}${checkImageSrc(
                  item.images,
                  "Thumbnail_Layer",
                )}`}
                alt=""
              />
              <DescrSpinItem>
                {item.name.split(" ").slice(-2, -1).join(" ")}
              </DescrSpinItem>
              <NameSpinItem>
                {item.name.split(" ").slice(-2).join(" ")}
              </NameSpinItem>
              <Tier tier={checkTier(item.partparams)} />
            </RoulleteItem>
          ))}
        </RoulleteImagesList>
      </RoulleteImagesWrapper>

      {visibleMultiWinSpin && props.winArr && (
        <Modal
          visible={visibleMultiWinSpin}
          onClose={() => {
            queryClient.invalidateQueries("getProfile").then(() => {
              setMultiVisibleWinSpin(false);
            });
          }}
        >
          <ModalMultiWinnerSpin
            winItems={props.winArr}
            freeType={props.freeType}
            onclose={() => {
              queryClient.invalidateQueries("getProfile").then(() => {
                setMultiVisibleWinSpin(false);
              });
            }}
          />
        </Modal>
      )}
    </RoulleteContainer>
  );
};

const DescrSpinItem = styled.div`
  position: absolute;
  color: #fff;
  border-radius: 6px;
  padding: 4px 13px;
  bottom: 50px;
  background: #000;
  z-index: 2;
  font-size: 14px;
`;

const NameSpinItem = styled.div`
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #fff;
  z-index: 1;
  margin-top: 20px;
`;

const CircleIconStyled = styled(CircleIcon)<{
  rarity: string;
}>`
  fill: ${props =>
    props.rarity === "Red"
      ? `red`
      : props.rarity === "Yellow"
      ? `yellow`
      : props.rarity === "Green"
      ? `green`
      : props.rarity === "Blue"
      ? `blue`
      : props.rarity === "Yellow"
      ? `yellow`
      : `#29efa8`};

  position: absolute;
  left: -45px;
  top: -50px;
`;

const BgRobotModal = styled.img`
  position: absolute;
  height: 130px;
  left: 45px;
`;

const RoulleteContainer = styled.div`
  width: 100%;
  height: 305px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
`;

const RoulleteImagesWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

const RoulleteImagesList = styled.div`
  position: absolute;
  left: 50%;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  display: flex;
`;

//ЗНАЧЕНИЕ ШИРИНЫ У ЭТОГО ЭЛЕМЕНТА НЕ МЕНЯТЬ. ОТ НЕГО ЗАВИСЯТ РАСЧЕТЫ default-width:220px;
const RoulleteItem = styled.div`
  display: inline;
  width: ${itemWidth}px;
  height: 220px;
  display: flex;
  justify-content: center;
  background: #0b1014;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid #fff659;
  position: relative;
  flex-direction: column;
`;

const RoulleteImg = styled.img`
  min-width: 80px;
  margin-top: 1px;
  margin-bottom: 1px;
  z-index: 1;
  margin-top: 25px;
`;

//ИНДИКАТОР - это СТРЕЛКА, которая показывает победителя
const RoulleteIndicator = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  left: 47%;
  margin: 0;
  height: 100%;
  background: none;
  z-index: 1;
  > img:last-child {
    transform: rotate(180deg);
  }
`;
