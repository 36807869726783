import axios from "axios";
import { QueryClient } from "react-query";

export const apiRoute = ` https://api.mipapp.link${process.env.REACT_APP_API_ROUTE}`;
export const envGlob = process.env.NODE_ENV;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export const imageRoute = `https://api.mipapp.link${process.env.REACT_APP_API_ROUTE}/images?image=`;

export default axios.create({
  baseURL: "https://api.mipapp.link",
  responseType: "json",
});
