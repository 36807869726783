import styled from "styled-components";
import borderMarket from "../../../assets/img/borderMarketItem.svg";
import { ReactComponent as CircleIcon } from "../../../assets/img/modalBGItemSpin.svg";
import { TedButton } from "../Button/TedButton";
import Slider from "react-slick";
import { AdminPartsType } from "../../../utils/commonTypes";
import { imageRoute } from "../../../utils/api";
import { checkImageSrc } from "../../../utils/commonFunctions";
//import { useHistory } from "react-router-dom";
import {
  SlickArrowLeftProfile,
  SlickArrowRightProfile,
} from "../SlickArrow/SlickArrow";
import lootItemBorderImg from "../../../assets/img/lootItemBorder.svg";

type PropsTypeWinSpin = {
  winItems?: AdminPartsType[];
  freeType: boolean;
  onclose: () => void;
};

export function ModalMultiWinnerSpin({
  onclose,
  winItems,
  freeType,
}: PropsTypeWinSpin) {
  //const history = useHistory();

  const settings = {
    infinite: false,
    speed: 400,
    slidesToScroll: 1,
    slidesToShow: 1,
  };

  // const closeModal = () => {
  //   freeType && history.push("/loot");
  // };

  const afterChangeHandler = (currentslide: number) => {
    //setActiveElForSeil(winItems[currentSlide]);
  };

  return (
    <ModalWrapper>
      <h3>Congratulations!</h3>
      <img src={lootItemBorderImg} alt="" />

      <SliderWrap
        {...settings}
        nextArrow={<SlickArrowRightProfile1 />}
        prevArrow={<SlickArrowLeftProfile2 />}
        afterChange={afterChangeHandler}
      >
        {winItems?.length &&
          winItems?.map(item => (
            <ItemModal key={item.id}>
              <ImgWrapper>
                <BgRobotModal src={borderMarket} alt="" />
                <CircleIconStyled />
                <RobotModal
                  src={`${imageRoute}${checkImageSrc(
                    item.images,
                    "Thumbnail_Layer",
                  )}`}
                  alt=""
                />
              </ImgWrapper>

              <TextModal>
                <h3>{item.value}</h3>
                <p>{item.trait_type}</p>
              </TextModal>
            </ItemModal>
          ))}
      </SliderWrap>
      <ModalButton>
        <TedButton
          size="medium"
          width="160"
          color="green"
          onclick={() => onclose()}
        >
          Fine
        </TedButton>
        <TedButton
          size="medium"
          width="160"
          color="blue"
          active={true}
          onclick={() => {}}
        >
          {`$ Sell for 66 MIP`}
        </TedButton>
      </ModalButton>
    </ModalWrapper>
  );
}

const ImgWrapper = styled.div`
  margin-top: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SliderWrap = styled(Slider)`
  display: flex;
  height: 257px;
  width: 370px;
`;

const SlickArrowRightProfile1 = styled(SlickArrowRightProfile)`
  position: absolute;
  right: 0;
`;

const SlickArrowLeftProfile2 = styled(SlickArrowLeftProfile)`
  position: absolute;
  margin-top: 4px;
  left: 0;
`;

const ModalWrapper = styled.div`
  width: 400px;
  display: flex;
  height: 410px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > h3 {
    font-size: 20px;
    line-height: 29px;
    text-align: center;
    margin-top: 20px;
    color: #fff;
  }

  > img {
  }
`;

const ModalButton = styled.div`
  display: flex;
  margin-top: 30px;

  > div {
    margin: 0 6px;
  }
`;

const CircleIconStyled = styled(CircleIcon)`
  position: absolute;
  fill: #29efa8;
  width: 250px;
`;

const RobotModal = styled.img`
  height: 180px;
  width: 180px;
  z-index: 2;
`;

const BgRobotModal = styled.img`
  position: absolute;
  height: 170px;
`;

const TextModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -15px;
  text-align: center;
  > h3 {
    margin: 23px 0 4px 0;
    font-size: 16px;
    line-height: 19px;
  }

  > p {
    font-size: 12px;
    line-height: 14px;
    color: #616d7a;
  }
`;

const ItemModal = styled.div`
  width: 183px;
  height: 243px;
  position: relative;
  color: #fff;
  display: flex;
  flex-direction: column;

  > h3 {
    margin: 23px 0 4px 0;
    font-size: 16px;
    line-height: 19px;
  }

  > p {
    font-size: 12px;
    line-height: 14px;
    color: #616d7a;
  }
`;
