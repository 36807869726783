import axios from "axios";
import { useContext } from "react";
import { useMutation } from "react-query";
import {
  connect,
  isGaragePaid,
  startPayment,
} from "../metamask/balanceAndGaragePaidExample";
import { apiRoute, queryClient } from "../utils/api";
import authHeader from "../utils/auth-header";
import { Context } from "../utils/commonFunctions";

type MintDataType = {
  robotId: number;
};

type MinResType = {
  status: string;
  error?: string;
  errorParts?: [string][];
  mess?: string;
};

function useMintRobot(setIsMinting: (a: boolean) => void) {
  const context = useContext(Context);

  const { mutate, isLoading, data, isSuccess } = useMutation(
    (data: MintDataType) =>
      axios.put<MinResType>(`${apiRoute}/blender/mint`, data, {
        headers: authHeader(),
      }),
    {
      //IN CURRENT MOMENT I USE IT FOR TESTS
      //FUNCTION CONNECT -> FOR CONNECTING WITH METAMASK
      //I think after succesfull finishing of connect() we will use paid function.
      //I don't find reason for using getWalletBalance and isGaragePaid

      onSuccess: res => {
        if (
          res.data.mess?.includes("gas not paid") ||
          res.data.error?.includes("not paid")
        ) {
          context?.setMessage("Please, pay the garage fee.");
          setIsMinting(true);
          connect().then(() => {
            //getWalletBalanceEth();
            startPayment().then(
              res => {
                if (res?.userHasPaidGarage) {
                  context?.setMessage("Error. Already paid.");
                  setIsMinting(false);
                } else if (res?.mess === "Transaction Failed") {
                  context?.setMessage("Error. Transaction failed.");
                  setIsMinting(false);
                } else {
                  isGaragePaid().then(garagePaidRes => {
                    garagePaidRes
                      ? context?.setMessage(
                          "Successfully paid. Please, try mint again!",
                        )
                      : context?.setMessage("Error. Something went wrong");
                    setIsMinting(false);
                  });
                }
              },
              rej => console.log("rej", rej),
            );
          });
        } else if (!res?.data.error) {
          context?.setMessage("Your MIP Id has been sent to Minting");
          queryClient
            .invalidateQueries("getProfile")
            .then(() => queryClient.invalidateQueries("getAllRobotsInProfile"))
            .then(() => window.location.reload());
        } else {
          context?.setMessage("Error. Something went wrong");
        }
      },
      onError: (error: Error) => {
        if (error.message.includes("305")) {
          context?.setMessage(`Error. Not enough detail for minting`);
        }
      },
    },
  );

  return { mutate, isLoading, data, isSuccess };
}

export { useMintRobot };
